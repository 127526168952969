<template>
    <div>
		<el-dialog :title="title" :visible.sync="open" width="1000px" @close="commitOnClose">
			<el-form ref="saveForm" :model="saveData" label-width="130px" style="width:400px; padding-bottom:30px">
				<el-form-item label="分润配置名称" prop="templateName" :rules="[{ required: true, message: '请输入分润配置名称', trigger: 'blur' }]">
					<template v-if="isAdd"><el-input v-model="saveData.templateName" placeholder="请输入分润配置名称"/></template>
					<template v-else>{{benefitTitle}}</template>
				</el-form-item>
			</el-form>
			<el-tabs type="border-card">
				<el-tab-pane :label="benefit.policyName" v-for="benefit in benefitList" :key="benefit.id">
					<table class="rate-table" style="width:950px">
						<tr>
							<th>业务类型</th>
							<th>卡类型</th>
							<th>T1成本（比例：%，封顶：元）</th>
							<th>T0成本（%）</th>
							<th>T0提现费成本（元）</th>
						</tr>
						<tr v-for="item in benefit.costList" :key="item.id">
							<td>{{item.payTypeName}}</td>
							<td>{{item.cardType | formateCardType}}</td>
							<td>
								<div>
									<span v-if="payTypeCode=='POS'&&item.cardType=='2'">比例：</span>
									<template v-if="isAdd"><CostInput v-model="item.t1Rate" :minVal="item.t1MinRate" :maxVal="item.t1MaxRate"></CostInput></template>
									<template v-else>{{item.t1Rate}}</template>
									
								</div>
								<div v-if="payTypeCode=='POS'&&item.cardType=='2'">
									<template v-if="isAdd">封顶<CostInput v-model="item.t1TopFee" :minVal="item.t1MinTopFee" :maxVal="item.t1MaxTopFee"></CostInput></template>
									<template v-else>{{item.t1TopFee}}</template>
									
									</div>
							</td>
							<td>
								<template v-if="isAdd"><CostInput v-model="item.t0Rate" :minVal="item.t0MinRate" :maxVal="item.t0MaxRate"></CostInput></template>
								<template v-else>{{item.t0Rate}}</template>
							</td>
							<td>
								<div v-if="payTypeCode=='POS'&&item.cardType=='1'">
									<template v-if="isAdd"><CostInput v-model="item.cashFee" :minVal="item.minCashFee" :maxVal="item.maxCashFee"></CostInput></template>
									<template v-else>{{item.cashFee}}</template>
									</div>
									<span v-else>-</span>
								</td>
						</tr>
					</table>
				</el-tab-pane>
			</el-tabs>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" v-if="isAdd" @click="checkForm">{{translate('FROM.SUBMIT')}}</el-button>
				<el-button type="primary" @click="cancel">{{translate('FROM.CANCEL')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {AgentApi} from '@/api';
	import CostInput from '@/components/costInput'
	export default {
		name: "CustomForm",
		props: {
			dicts: {
				type: Object,
				default: function () {
					return {}
				}
			},
			isAdd: {
				type: Boolean,
				default: true,
			},
			selectRow: {
				type: Object,
				default: function () {
					return {}
				},
			},
			onFresh: {
				type: Boolean,
				default: false
			},
			templateNo:{
				type: Number,
				default:0
			},
			benefitTitle:{
				type: String,
				default:''
			}
		},
		components:{
			CostInput
		},
		data() {
			return {
				title: '',
				saveData: {},
				open: false,
				parentIdReadOnly: false,
				benefitList:'',
			}
		},
		watch: {
			onFresh: function (value) {
				this.open = value;
				if (value) {
					this.resetData();
					if(!this.isAdd){
						AgentApi.agentCostTemplate.detail(this.templateNo)
						.then(res=>{
							if(res.success){
								this.benefitList = res.data
							}
						})
					} else{
						AgentApi.agentCostTemplate.beforeCostSave()
						.then(res=>{
							if(res.success){
								this.benefitList = res.data
							}
						})
					}
					
				}
			}
		},
		created() {
			
		},
		methods: {
			async resetData() {
				if (this.isAdd){
					this.title = '新增分润配置';
					this.parentIdReadOnly = !!this.selectRow.menuId;
					this.benefitList = ''
				}else {
					this.saveData = this.selectRow;
					this.isAdd = false;
					this.title = '查看分润配置';
					this.benefitList = ''
				}
			},
			async submitForm() {
				let params = {policyInfoList:'',templateName: this.saveData.templateName}
				params.policyInfoList = this.benefitList
				let result = (this.isAdd) ? (await AgentApi.agentCostTemplate.save(params)) : '';
				if (result.success) {
					this.Message.success(this.translate(this.isAdd ? 'FROM.ADD_SUCCESS' : 'FROM.UPDATE_SUCCESS'));
					this.commitOnClose();
				}
			},
			checkForm(){
				this.$refs.saveForm.validate((valid) => {
					if (valid) {
						this.submitForm()
					} else {
						return false;
					}
					});
			},
			cancel() {
				this.saveData = {};
				this.commitOnClose();
			},
			commitOnClose() {
				this.$emit('on-close');
			}
		}
	};
</script>

<style scoped>

</style>
