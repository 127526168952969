<template>
    <div>
		<el-table stripe border v-loading="loading"  height="400" :data="dataList" lazy>
			<el-table-column label="分润配置名称" prop="templateName" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="状态" prop="" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					{{scope.row.state == 'DISABLE' ? '失效' :'正常'}}
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作" class-name="small-padding fixed-width" width="250" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-delete"
                               @click="handleDetail(scope.row)">详情</el-button>
					<el-button size="mini" type="text" icon="el-icon-edit"
					@click="handleDelete(scope.row)" v-permission="'AGENT_INFO_RESET_PASSWORD'">删除</el-button>
                </template>
            </el-table-column>
		</el-table>
		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
		<BenefitDialog :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :isAdd="isAdd" :templateNo="templateNo" :benefitTitle="title"></BenefitDialog>
	</div>
</template>

<script>
	import {AgentApi} from '@/api';
	import BenefitDialog from "@/components/benefitDialog/Index";
	export default {
		name: "ToolBar",
		components: {BenefitDialog},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			reQuery: {
				type: Number
			},
		},
		data() {
			return {
				pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
				dataList: [],
				selectRow: {},
				showCustomForm: false,
				isAdd: false,
				templateNo:'',
				title:''
			}
		},
		mounted() {
			this.getList();
		},
		watch: {
			reQuery: function () {
				this.getList();
			}
		},
		methods: {
			handlerCustomFormOnclose() {
				this.showCustomForm = false;
				this.getList();
			},
			async getList() {
				this.loading = true;
				let result = await AgentApi.agentCostTemplate.list(this.pageNo,this.pageSize);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			},
			handleDetail(row){
				this.showCustomForm = true;
				this.isAdd = false;
				this.templateNo = row.templateNo
				this.title = row.templateName
			},
			async handleUpdate(row) {
				this.showCustomForm = true;
				this.isAdd = false;
			},
			handleDelete(row){
				this.$confirm("是否确定编号为"+row.templateNo+"的分润配置?",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await AgentApi.agentCostTemplate.remove(row.templateNo);
                    result.success ? this.Message.success('删除成功') : this.Message.success('删除失败') 
					this.getList();
                });
			},
		}
	};
</script>

<style scoped>

</style>
